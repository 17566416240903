// Import styles
require('./src/assets/css/bootstrap.min.css');
require('animate.css');
require('./src/assets/css/boxicons.min.css');
require('./src/assets/css/flaticon.css');
require('./src/assets/css/slick.css');
require('react-accessible-accordion/dist/fancy-example.css');
require('./src/assets/css/style.css');
require('./src/assets/css/responsive.css');
require('react-toastify/dist/ReactToastify.css');

// If you want Multicolor, comment out single
// require('./src/assets/css/colors/brink-pink-style.css');
// require('./src/assets/css/colors/pink-style.css');
// require('./src/assets/css/colors/purple-style.css');

// Export the onClientEntry function
exports.onClientEntry = () => {
  window.onload = () => {
    console.log('window.onload');

    const script = document.createElement('script');
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());

      gtag('config', 'G-8DYDVYYWED');
    };
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-8DYDVYYWED';
    document.head.appendChild(script);
  };
};
